import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Login from '../views/Login.vue'
import App from '../views/App.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/app',
    name: 'App',
    component: App,
  },
  {
    path: '/movimientos/:id',
    name: 'Movimientos',
    component: () => import('../components/movimientos.vue')
  },
  {
    path: '/conceptos',
    name: 'Conceptos',
    component: () => import('../components/catalogs/conceptos.vue')
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: () => import('../components/catalogs/usuarios.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
