<template>
  <div class="pt-12 mx-auto" style="max-width:500px!;">
    <v-img width="50" class="mx-auto mt-12" src="https://uno.unocrm.mx/files/logo_unonegocios_circulo.png"></v-img>
    <v-list-item>
      <v-list-item-content class="text-center">
        <v-list-item-title class="title my-4" style="font-size:30px!important; color:#ff5000;">¡hola!</v-list-item-title>
        <v-list-item-subtitle style="font-size:21px!important;">inicia sesión</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card class="ma-4" elevation="0">
      <v-card-text>
        <v-form>
          <v-text-field 
            :rules="[rules.required]"
            @keydown.enter="login" 
            label="Usuario | Correo Electrónico" 
            name="login" 
            v-model="user.email" 
          ></v-text-field>
          <v-text-field 
            :rules="[rules.required]"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            name="password"
            label="Contraseña"
            @click:append="show1 = !show1"
            @keydown.enter="login" v-model="user.password" 
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn block color="#ff5000" dark type="submit" @click="login" class="btn btn-primary px-4"><strong>Acceder</strong></v-btn><!-- color="#32241c" -->
      </v-card-actions>
    </v-card>
    <div style="position: fixed; left: 50%; bottom: 20px; transform: translate(-50%, -50%); margin: 0 auto;">
      <router-link to="/">¿Olvidaste tu contraseña?</router-link>
    </div>
  </div>
</template>
<script>
  export default{
    data:()=>({
      show: false,
      show1: false,
      password: 'Password',
      rules: {
        required: value => !!value || 'Contraseña requerida.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inavlido.'
        }
      },
      user:{
        email: "",
        password: ""
      }
    }),
    methods:{
      login(){
        this.$store.dispatch('currentUser/loginUser', this.user);
      }, 
    },
  }
</script>