import axios from "axios";
const state = {
  user: {},
};
const getters = {};
const actions = {
  getUser({ commit }) {
    axios.get("https://unoweb.uno/api/v1/user/current", 
    
    ).then(response => {
      commit("setUser", response.data);
    });
  },
  loginUser({}, user) {
    axios
      .post("https://unoweb.uno/api/v1/user/login", {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem("blog_token", response.data.access_token);
          window.location.replace("#/app");
          location.reload();
        }
      });
  },
  logoutUser() {
    localStorage.removeItem("blog_token");
    window.location.replace("#/");
    location.reload();
  }
};
const mutations = {
  setUser(state, data) {
    state.user = data;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
