import Vue from 'vue'
import Vuex from 'vuex'

import currentUser from "./modules/currentUser"
import concepto from "./modules/concepto"
import movimiento from "./modules/movimiento"
import user from "./modules/user"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    currentUser,
    concepto,
    movimiento,
    user
  }
})
