import axios from "axios";
const state = {
    conceptos:[]
};
const getters = {};

const actions = {
    getConceptos( {commit} ){
        axios
        .get("https://unoweb.uno/api/v1/concepto/all")
        .then(response => {
            commit('setConceptos', response.data);
        });
    }
};

const mutations = {
    setConceptos(state, data){
        state.conceptos = data;
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}