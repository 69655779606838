<template>
  <v-sheet style="background-color:#ff5100!important;" class="fill-height overflow-hidden py-12">
    <v-container class="fill-height pb-12">
      <v-row class="px-6">
        <div style="color:white; font-size:32px;">¡Hola {{currentUser.name}}! </div>
        <v-divider style="border:none!important;" ></v-divider>
        <v-btn dark class="pa-6" icon @click.stop="drawer = !drawer" >
          <v-icon large> mdi-menu</v-icon>
        </v-btn>
      </v-row>
      <div class="px-6" style="margin-bottom:-30px; color:white; font-size:21px;">Empresas</div>
      <v-card class="mx-auto elevation-6" max-width="344" outlined>
        <v-list-item three-line class="pa-4">
            <v-img style="max-width:100px!important;" class="mr-6" src="/files/efiindustrial.svg"></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline title">
              EFI Industrial
            </v-list-item-title>
            <v-list-item-subtitle style="font-size:21px;">{{total('efi')}}</v-list-item-subtitle>
          </v-list-item-content>
          <router-link class="liga" absolute icon right :to="{ path: '/movimientos/'+'efi'}" append>
            <v-btn style="min-width:0px; width:10px;" class="elevation-0">
              <v-icon large> mdi-chevron-right</v-icon>
            </v-btn>
          </router-link>
        </v-list-item>
      </v-card>
      <v-card class="mx-auto elevation-6" max-width="344" outlined>
        <v-list-item three-line class="pa-4">
            <v-img style="max-width:100px!important;" class="mr-6" src="/files/unoferre.png"></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline title">
              UNO Ferre
            </v-list-item-title>
            <v-list-item-subtitle style="font-size:21px;">{{total('unoferre')}}</v-list-item-subtitle>
          </v-list-item-content>
          <router-link class="liga" absolute icon right :to="{ path: '/movimientos/'+'unoferre'}" append>
            <v-btn style="min-width:0px; width:10px;" class="elevation-0">
              <v-icon large> mdi-chevron-right</v-icon>
            </v-btn>
          </router-link>
        </v-list-item>
      </v-card>
      <v-card class="mx-auto elevation-6" max-width="344" outlined>
        <v-list-item three-line class="pa-4">
            <v-img style="max-width:100px!important;" class="mr-6" src="/files/unonegocios.png"></v-img>
          <v-list-item-content>
            <v-list-item-title class="headline title">
              UNO Negocios
            </v-list-item-title>
            <v-list-item-subtitle style="font-size:21px;">{{total('unonegocios')}}</v-list-item-subtitle>
          </v-list-item-content>
          <router-link class="liga" absolute icon right :to="{ path: '/movimientos/'+'unonegocios'}" append>
            <v-btn style="min-width:0px; width:10px;" class="elevation-0">
              <v-icon large> mdi-chevron-right</v-icon>
            </v-btn>
          </router-link>
        </v-list-item>
      </v-card>
      <v-card class="mx-auto elevation-6" max-width="344" outlined>
        <v-list-item three-line class="pa-4">
            <!--v-img style="max-width:100px!important;" class="mr-6" src="/files/unonegocios.png"></v-img-->
          <v-list-item-content>
            <v-list-item-title class="headline title">
              FGS
            </v-list-item-title>
            <v-list-item-subtitle style="font-size:21px;">{{total('fgs')}}</v-list-item-subtitle>
          </v-list-item-content>
          <router-link class="liga" absolute icon right :to="{ path: '/movimientos/'+'fgs'}" append>
            <v-btn style="min-width:0px; width:10px;" class="elevation-0">
              <v-icon large> mdi-chevron-right</v-icon>
            </v-btn>
          </router-link>
        </v-list-item>
      </v-card>
    </v-container>
    <!-- sidebar -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list-item style="background:#0e2c42;" class="pt-12 pb-6">
        <v-list-item-avatar style="background-color:#ff5000!important;">
          <div style="color:white;">FG</div>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title style="color:white;">{{currentUser.name}}</v-list-item-title>
          <v-list-item-subtitle style="color:white;">{{currentUser.email}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list dense>
        <v-list-item class="py-2 px-6" to="/conceptos">
          <v-list-item-icon>
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Conceptos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="currentUser.email=='fergo'" class="py-2 px-6" to="/usuarios">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div style="position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:100%;">
        <v-divider></v-divider>
        <v-list class="pb-0" dense>
          <v-list-item @click="logout()">
            <v-list-item-icon dark>
              <v-icon> mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cerrar Sesión</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <!-- absolute -->
    <div style="position: fixed; left: 50%; bottom: -10px; transform: translate(-50%, -50%); margin: 0 auto; width:90%; border-radius: 30px!important; background: rgb(255 255 255 / 0.21);">
        <v-row class="text-center px-4 py-2" style="color:white;">
          <v-col @click="dialogPrestamo=true" style="cursor:pointer;" cols="4">
            <v-icon dark>mdi-repeat</v-icon>
            <br>Prestamos
          </v-col>
          <v-col @click="movimiento(-1)" style="cursor:pointer;" cols="4">
            <v-icon small dark> mdi-minus</v-icon><v-icon dark>mdi-currency-usd</v-icon>
            <br>Gasto
          </v-col>
          <v-col @click="movimiento(1)" style="cursor:pointer;" cols="4">
            <v-icon small dark>mdi-plus</v-icon><v-icon dark>mdi-currency-usd</v-icon>
            <br>Ingreso
          </v-col>
        </v-row>
    </div>
    <v-dialog v-model="dialogMovimiento" max-width="400px">
      <altaMovimiento v-bind:datosMovimiento="datosMovimiento" @cerrarMovimiento="cerrarMovimiento"></altaMovimiento>
    </v-dialog>
    <v-dialog v-model="dialogPrestamo" max-width="400px">
      <altaPrestamo @cerrarPrestamo="cerrarPrestamo"></altaPrestamo>
    </v-dialog>
  </v-sheet>
</template>
<script>
  import altaMovimiento from "../components/dialogs/altaMovimiento"
  import altaPrestamo from "../components/dialogs/prestamos"
  export default {
    data () {
      return {
        datosMovimiento:{
          tipo:'', usuario:'',
        },
        dialogPrestamo: false,
        dialogMovimiento: false,
        drawer: null,
        items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
      }
    },
    methods:{
      logout(){
        this.$store.dispatch('currentUser/logoutUser')
      },
      movimiento(tipo){
        this.datosMovimiento.tipo = tipo
        this.datosMovimiento.usuario = this.currentUser.id
        this.dialogMovimiento = true
      },
      cerrarMovimiento: function(params) {
        this.dialogMovimiento = params;
      },
      cerrarPrestamo: function(params) {
        this.dialogPrestamo = params;
      },
      total(empresa){
        var movimientos = this.$store.state.movimiento.movimientos.filter(movimiento => movimiento.recibido != 1).filter(movimiento => movimiento.empresa == empresa)
        var suma = movimientos.reduce((a, b) => a + (b['monto'] || 0), 0)
        return suma.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})
      }
    },
    created(){
      this.$store.dispatch('currentUser/getUser')
      this.$store.dispatch('movimiento/getMovimientos')
    },
    computed:{
      currentUser:{
        get(){
          return this.$store.state.currentUser.user
        }
      },
    },
    components:{ 
      'altaMovimiento':altaMovimiento,
      'altaPrestamo':altaPrestamo,
    },
  }
</script>
<style>
  .v-application a {
    color: white!important;
  }
</style>