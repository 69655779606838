<template>
  <v-flex xs12>
      <!-- Titulo del dialogo -->
      <v-card>
        <v-card-title>
          <span class="headline">{{title}}</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <!-- Formulario Empresa -->
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col v-if="datosMovimiento.empresa==null" cols="12" sm="6" md="4" class="py-0">
                    <v-select :rules="[v => !!v || 'Campo requerido']" required v-model="altaMovimiento.empresa" :items="empresas" item-text="text" item-value="item" label="Empresa"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-autocomplete :rules="[v => !!v || 'Campo requerido']" required clearable v-model="altaMovimiento.concepto" :items="conceptosList" item-text="concepto" item-value="id" label="Concepto">
                        <template slot="no-data">
                            <div class="px-4">No existen conceptos relacionados.</div>
                            <v-text-field class="px-4" v-model="altaConcepto.concepto" label="Nuevo Concepto" append-icon="mdi-plus" @click:append="saveConcepto()"></v-text-field>
                        </template>
                    </v-autocomplete>
                </v-col> 
                <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-text-field :rules="[v => !!v || 'Campo requerido']" required type="number" prepend-icon="mdi-currency-usd" v-model="altaMovimiento.monto" label="Monto"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-0">
                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="altaMovimiento.fecha" label="Fecha" prepend-icon="mdi-calendar-today" readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker color="primary" v-model="altaMovimiento.fecha" @input="menu = false"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="8" class="py-0">
                    <v-textarea v-model="altaMovimiento.nota" label="Nota"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <!-- Cancelar y Guardar -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cerrar()">Cancelar</v-btn>
          <v-btn color="blue darken-1" text :disabled="!valid" @click="saveMovimiento">Guardar</v-btn>
        </v-card-actions>
      </v-card>
  </v-flex>
</template>


<!---------- SCRIPTS ---------->

<script>
import axios from "axios"
export default {
    props:{
        datosMovimiento:Object
    },
    data: () => ({
        menu:false,
        empresas:[
            {item:'efi', text:'EFI Industrial'},
            {item:'unoferre', text:'UNO Ferre'},
            {item:'unonegocios', text:'UNO Negocios'},
            {item:'fgs', text:'FGS'}
        ],
        falso:false,
        valid: true,
        altaMovimiento:{
            monto:'',
            empresa:'',
            concepto:'',
            usuario:'',
            nota:'',
            recibido:'',
            fecha:'',
        },
        altaConcepto:{
            concepto:'',
            tipo:''
        }
    }),  
    computed: { 
        conceptosList(){
            var tipo
            if(this.datosMovimiento.tipo==-1){
                tipo='gasto'
            }else if(this.datosMovimiento.tipo==1){
                tipo='ingreso'
            }else{
                tipo='ambos'
            }
            return this.$store.state.concepto.conceptos.filter(concepto=>concepto.tipo == tipo);
        },
        title(){
            this.altaMovimiento.empresa = this.datosMovimiento.empresa
            this.altaMovimiento.usuario = this.datosMovimiento.usuario
            if(this.datosMovimiento.tipo==-1){
                return 'Alta de Gasto'
            }else if(this.datosMovimiento.tipo==1){
                return 'Alta de Ingreso'
            }
        }
    },
    created(){
        this.$store.dispatch('concepto/getConceptos')
        this.$store.dispatch('movimiento/getMovimientos')
    },
    methods: { 
        cerrar(){
            Object.keys(this.altaMovimiento).forEach(key => {
                return (this.altaMovimiento[key] = "");
            }); 
            Object.keys(this.datosMovimiento).forEach(key => {
                return (this.datosMovimiento[key] = "");
            }); 
            this.$emit("cerrarMovimiento", this.falso);
        },
        saveMovimiento(){
            this.altaMovimiento.monto = this.altaMovimiento.monto*this.datosMovimiento.tipo
            this.altaMovimiento.recibido=1,
            axios.post('https://unoweb.uno/api/v1/movimiento/guardar',Object.assign(this.altaMovimiento))
            .then(resp => {
                this.cerrar()
            })
        },
        saveConcepto(){
            if(this.datosMovimiento.tipo==-1){
                this.altaConcepto.tipo='gasto'
            }else if(this.datosMovimiento.tipo==1){
                this.altaConcepto.tipo='ingreso'
            }
            axios.post('https://unoweb.uno/api/v1/concepto/guardar',Object.assign(this.altaConcepto)).then(resp => {
                Object.keys(this.altaConcepto).forEach(key => {
                    return (this.altaConcepto[key] = "");
                }); 
                this.$nextTick(() => {
                    this.$store.dispatch('concepto/getConceptos')
                })
            })
        }
    }, 
}
</script>